import React from "react";

const App = () => {
  return (
    <>
      <iframe
        src="https://360.goku.agency/map/"
        frameborder="0"
        width="100%"
        height="100%"
        title="house"
      />
    </>
  );
};

export default App;
